import { DataFetcher } from "components/DataFetcher";
import DataTable from "components/DataTable/DataTable";
import Pod from "components/Pod/Pod";
import { useCallback, useRef, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import "./ContentUsage.scss";
import { getFetchOptions } from "utils/fetch";

const ContentUsage = () => {
	const [fetchData, setFetchData] = useState({ url: "api/v3/Report/ContentUsage/ContentGroups/ByCompany" });
	const [contentGroups, setContentGroups] = useState(null);
	const [selectedContentGroups, setSelectedContentGroups] = useState(null);
	const [usageData, setUsageData] = useState(null);
	const multiSelectRef = useRef(null);
	const [expandedModuleRows, setExpandedModuleRows] = useState(null);
	const [expandedSubmoduleRows, setExpandedSubmoduleRows] = useState(null);

	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);

	/**
	 * @note Fetch request different success return data:
	 *   1) GET /api/v3/Report/ModuleUsage/ContentGroups/ByCompany - List<IdValueData<string>>
	 *   2) GET /api/v3/Report/ModuleUsage/ByCompany - List<ModuleUsageDto>
	 */
	const handleData = useCallback((data) => {
		if (data && Array.isArray(data)) {
			if (data[0].Name) {
				data.sort((a, b) => { return a.Position - b.Position });
				setContentGroups(data);
			}
			else if (data[0].UserId) {
				setUsageData(data);
			}
		}
	}, []);

	const handleBlueContentGroups = () => {
		if (selectedContentGroups && selectedContentGroups.length) {
			const options = getFetchOptions({
				method: "POST",
				body: selectedContentGroups.map(curr => curr.Id)
			});
			setFetchRequestData("api/v3/Report/ContentUsage/ByCompany", options);
			multiSelectRef.current.hide();
		}
		else {
			setUsageData(null);
		}
	};

	const getCount = (type, item) => {
		return item.length + " " + type + (item.length !== 1 ? "s" : "");
	};

	const getStatus = (item) => {
		let title = "Not started";
		let className = "not-started";
		if (item.TotalProgress === 100 || item.OverriddenCaptivateProgress === 100) {
			title = "Completed";
			className = "completed";
		}
		else if (item.TotalProgress > 0 || item.OverriddenCaptivateProgress > 0) {
			title = "In progress";
			className = "in-progress";
		}
		return <div className={`status-pill ${className}`}>{title}</div>;
	};

	const userColumns = [
		{
			expander: (data) => Object.keys(data.ModuleCompletionData).length > 0,
			style: { width: "3rem" }
		},
		{
			field: "FullName",
			headerName: "Name",
			valueGetter: (data) => { return data.FullName; },
			sortable: true
		},
		{
			headerName: "Modules",
			class: "count-column",
			valueGetter: (data) => { return getCount("Module", Object.keys(data.ModuleCompletionData)); }
		},
		{
			headerName: "Status",
			class: "status-column",
			valueGetter: (data) => { return getStatus(data); }
		}
	];

	const moduleColumns = [
		{
			expander: (data) => Object.keys(data.SubmodulesByModule).length > 0,
			style: { width: "3rem" }
		},
		{
			field: "Name",
			headerName: "Module",
			valueGetter: (data) => { return data.Name; }
		},
		{
			headerName: "Submodules",
			class: "count-column",
			valueGetter: (data) => { return getCount("Submodule", Object.keys(data.SubmodulesByModule)); }
		},
		{
			headerName: "Status",
			class: "status-column",
			valueGetter: (data) => { return getStatus(data); }
		}
	];

	const submoduleColumns = [
		{
			field: "Name",
			headerName: "Submodule",
			valueGetter: (data) => { return data.Name; }
		},
		{
			headerName: "Status",
			class: "status-column",
			valueGetter: (data) => { return getStatus(data); }
		}
	];

	const ContentGroupSelection = (
		<div className="contentgroup-input">
			<MultiSelect
				ref={multiSelectRef}
				className="contentgroup-select"
				value={selectedContentGroups}
				onChange={(e) => setSelectedContentGroups(e.value)}
				onHide={handleBlueContentGroups}
				options={contentGroups}
				optionLabel="Name"
				placeholder="Select Content Groups"
				selectAllLabel="Select All"
				maxSelectedLabels={3}
			/>
		</div>
	);

	const submoduleTemplate = (data) => {
		const submoduleCompletionData = Object.keys(data.SubmodulesByModule).map((key) => {
			let existing = data.SubmodulesByModule[key];
			existing.Id = Number(key);
			return existing;
		});
		return (
			<div className="mb-3">
				<DataTable
					columns={submoduleColumns}
					rows={submoduleCompletionData}
				/>
			</div>
		);
	};

	const moduleTemplate = (data) => {
		const moduleCompletionData = Object.keys(data.ModuleCompletionData).map((key) => {
			let existing = data.ModuleCompletionData[key];
			existing.Id = Number(key);
			return existing;
		});
		return (
			<div className="mb-3">
				<DataTable
					columns={moduleColumns}
					rows={moduleCompletionData}
					expandedRows={expandedSubmoduleRows}
					onRowToggle={(e) => setExpandedSubmoduleRows(e.data)}
					rowExpansionTemplate={submoduleTemplate}
				/>
			</div>
		);
	};

	return (
		<div className="gutters">
			<DataFetcher fetchData={fetchData} setData={handleData} />
			<Pod title="Content Usage">
				<div className="contentusage-container">
					{ContentGroupSelection}
					{usageData && (
						<DataTable
							columns={userColumns}
							rows={usageData}
							expandedRows={expandedModuleRows}
							onRowToggle={(e) => setExpandedModuleRows(e.data)}
							rowExpansionTemplate={moduleTemplate}
						/>
					)}
				</div>
			</Pod>
		</div>
	);
};

export default ContentUsage;