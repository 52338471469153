import { Button, ButtonType } from "components/Button/Button";
import ContactDetails from 'components/ContactDetails/ContactDetails';
import MediaType from "components/MediaType/MediaType";
import { useModal } from 'components/Modal/context/ModalContext';
import useUserData from "hooks/useUserData";
import { useNavigate } from "react-router-dom";
import { ModuleCustomType, ModuleStateType } from "utils/constants";
import { UserRoles } from "utils/userRoles";
import "./ModuleItem.scss";
import ProgressIcon from "./ProgressIcon";
import SubModuleAccordion from "./SubModuleAccordion";
import { useRef } from "react";
import useBranding from "hooks/useBranding";

const ModuleItem = ({ course, module, expandedModuleId, onExpand }) => {
	const { contactDetails } = useBranding();
	const { userData } = useUserData();
	const { openModal } = useModal();
	const navigate = useNavigate();
	const headerRef = useRef(null); // Create a ref for the module name h3
	
	const handleClick = () => {
		navigate(`/player#courseId-${course.Id}-moduleId-${module.Id}-submoduleId-${module.LastViewed.Id}`);
	};

	const handleOpenLockModal = () => {
		const modalTitle = "Unlock Modules";
		const modalContent =
			userData.UserLevel !== UserRoles.Learner ? (
				<div>
					<p>This module is part of a package you don't have access to. Contact us to ask about getting access for your organisation on:</p>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<ContactDetails number={contactDetails.Number} email={contactDetails.Email} />
					</div>
				</div>
			) : (
				<div>
					<p>This module is part of a package you don't have access to. Please contact your company's ucSKILLS Admin to access this content.</p>
				</div>
			);
		openModal(modalTitle, modalContent, true);
	};

	const showAccordion = () => {
		return module.SubSubContentCount > 1 || module.CustomTypeId === ModuleCustomType.DeviceTraining;
	};

	const getButtonText = (progress) => {
		switch (progress) {
			case 0:
				return "Start";
			case 100:
				return "Restart";
			default:
				return "Continue";
		}
	};

	const renderButton = () => {
		if (module.State === ModuleStateType.Enabled) {
			return module.ContentType.length ? (<Button type={ButtonType.Primary} text={getButtonText(module.Progress)} onClick={handleClick} />) : null;
		}
		if (module.State === ModuleStateType.DisabledUpsell) {
			return (<Button type={ButtonType.Secondary} text={"Unlock"} onClick={handleOpenLockModal} />);
		}
	};

	return (
		<div className="list-view-item-item" id={`courseId-${course.Id}-moduleId-${module.Id}`}>
			<div className="list-view-item-item-progress">
				<ProgressIcon
					state={module.State}
					hasContent={module.ContentType.length}
					className="list-view-item-item-progress-container"
					hasProgress={module.HasProgress}
					progress={module.Progress}
					handleClick={handleClick}
					handleOpenLockModal={handleOpenLockModal}
					showPercentage={true}
				/>
			</div>
			<div className="list-view-item-item-details">
				<div className="list-view-item-item-details-topline">
					<h3 ref={headerRef}>{module.Name}</h3>
					{module.Duration && <p className="small-text">({module.Duration})</p>}
					<MediaType type={module.ContentType} />
				</div>
				<p className="list-view-item-item-details-description">{module.Description}</p>
				{renderButton()}
				{showAccordion() && (
					<SubModuleAccordion
						expanded={module.Id === expandedModuleId}
						courseId={course.Id}
						moduleId={module.Id}
						submoduleCount={module.SubSubContentCount}
						guruUrl={module.GuruUrl}
						webcast={module.HasWebcast}
						contactNumber={contactDetails.Number}
						state={module.State}
						handleOpenLockModal={handleOpenLockModal}
						onExpand={() => onExpand(module.Id)}
						headerRef={headerRef}
					/>
				)}
			</div>
		</div>
	);
};

export default ModuleItem;
