import { Button } from "components/Button/Button";
import "./SearchResultItem.scss";
import MediaType from "components/MediaType/MediaType";

const SearchResultItem = ({ searchResults, searchTerm }) => {

	const escapeRegExp = (string) => {
		return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	};

	const highlightTerm = (text, term) => {
		if (!term) {
			return text;
		}

		const escapedTerm = escapeRegExp(term);
		const regex = new RegExp(`(${escapedTerm})`, 'gi');
		const parts = text.split(regex);
		return parts.map((part, index) =>
			regex.test(part) ? <span key={index} className="searchTerm">{part}</span> : part
		);
	};

	const getButtonText = (progress) => {
		switch (progress) {
			case 0:
				return "Start";
			case 100:
				return "Restart";
			default:
				return "Continue";
		}
	};

	if (!searchResults.Results || searchResults.Results.length === 0) {
		return searchResults.HasUnassignedContent ? <div className="searchResultItem">
				These results contain content not assigned to you. Ask your ucSKILLS Admin to unlock this content for you.
			</div>
			: <div>
				No results found.
			</div>;
	}

	return (
		<>
			{searchResults.Results.map(searchResult => (
				<div key={searchResult.Id} className="searchResultItem">
					<div className="searchResultItem-details">
						<div className="searchResultItem-details-text">
							<div className="searchResultItem-details-text-topline">
								<h2>{highlightTerm(searchResult.ModuleName, searchTerm)}</h2>
								<p>{<MediaType type={searchResult.ContentType} />}</p>
							</div>
							<p>{highlightTerm(searchResult.CourseName, searchTerm)} <strong>&gt;</strong> {highlightTerm(searchResult.ModuleName, searchTerm)}</p>
						</div>
						<div className="searchResultItem-details-button">
							<Button
								to={`/player#courseId-${searchResult.CategoryId}-moduleId-${searchResult.CourseId}-submoduleId-${searchResult.ModuleId}`}
								text={getButtonText(searchResult.Progress)}
							/>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default SearchResultItem;
