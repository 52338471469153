import React from 'react';
import './MediaType.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faClipboardList, faComputerMouse } from '@fortawesome/free-solid-svg-icons';

const MediaType = ({ type }) => {
	const typeLowerCase = type.toLowerCase();

	const setVideo = typeLowerCase.includes("video");
	const setInteractive = typeLowerCase.includes("interactive");
	const setAssessment = typeLowerCase.includes("suite");

	return (
		<div className="mediaType">
			{setVideo && <FontAwesomeIcon icon={faVideo} />}
			{setInteractive && <FontAwesomeIcon icon={faComputerMouse} />}
			{setAssessment && <FontAwesomeIcon icon={faClipboardList} />}
		</div>
	);
};

export default MediaType;
