import { useCallback } from "react";
import useFetch from "hooks/useFetch";
import { getFetchOptions } from "utils/fetch";
import { packageIsScorm } from "features/Shared/ContentData/PackageType";

const usePlayerTerminate = (blocker, unloading, data) => {
	const { execute, executingFetch } = useFetch();

	const executeXapiTermination = useCallback((messageData, blocker) => {
		const options = getFetchOptions({
			method: "PUT",
			body: {
				Module: data.Module,
				Submodule: data.Submodule,
				SectionData: {
					Name: messageData.sectionName || "",
					Ids: messageData.sectionIds || ""
				},
				RegistrationId: data.RegistrationId,
				Duration: messageData.Duration || 0,
				Ranges: messageData.Ranges || []
			}
		});

		// This seems to run twice, even with the preventative measures (without these, it runs several times).
		if (!executingFetch && unloading.current === 0) {
			++unloading.current;
			execute("/api/Xapi/PutTerminate", options, (result, error) => {
				if (blocker.state === "blocked") {
					blocker.proceed();
					--unloading.current;
				}
			});
		}
	}, [data, blocker, executingFetch]);

	const executeScormTermination = useCallback((messageData, blocker) => {
		const options = getFetchOptions({
			method: "PUT",
			body: {
				Id: messageData.Id,
				Property: "cmi.core.exit",
				Value: messageData.Value
			}
		});
		if (!executingFetch && unloading.current === 0) {
			++unloading.current;
			execute("/api/statements/cmi", options, (result, error) => {
				if (blocker.state === "blocked") {
					blocker.proceed();
					--unloading.current;
				}
			});
		}
	}, [data, blocker, executingFetch]);

	const postTerminate = useCallback((messageData) => {
		if (!data.Type || !packageIsScorm(data.Type)) {
			executeXapiTermination(messageData, blocker);
		}
		else {
			executeScormTermination(messageData, blocker);
		}
	}, [data, blocker]);

	return {
		postTerminate
	};
};

export default usePlayerTerminate;