import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Model } from "survey-core";
import "survey-core/defaultV2.css";
import { Survey } from "survey-react-ui";
import { Button } from "../../../components/Button/Button";
import "./SurveyContainer.scss";

const SurveyContainer = ({ assessment, onComplete, onRestart }) => {
	const [isComplete, setIsComplete] = useState(false);
	const [quizResults, setQuizResults] = useState(null);
	const [survey, setSurvey] = useState(new Model(assessment));

	const handleComplete = (sender) => {
		const userAnswers = sender.data;
		const totalQuestions = assessment.pages.length;
		if (assessment.Test) {
			let correctAnswers = 0;

			assessment.pages.forEach((page) => {
				const question = page.elements[0];
				const userAnswer = userAnswers[question.name];
				const correctAnswer = question.correctAnswer;

				if (Array.isArray(correctAnswer)) {
					if (JSON.stringify(userAnswer?.sort()) === JSON.stringify(correctAnswer?.sort())) {
						++correctAnswers;
					}
				}
				else if (
					typeof userAnswer === "string" &&
					typeof correctAnswer === "string" &&
					userAnswer.trim().toLowerCase() === correctAnswer.trim().toLowerCase()
				) {
					++correctAnswers;
				}
				else if (userAnswer === correctAnswer) {
					++correctAnswers;
				}
				page.answer = userAnswer;
				if (page.elements.length > 1) {
					page.feedback = userAnswers[page.elements[1].name];
				}
			});

			const scorePercentage = (correctAnswers / totalQuestions) * 100;
			const passed = scorePercentage >= assessment.PassPercentage;

			setQuizResults({
				scorePercentage,
				passed,
				totalQuestions,
				correctAnswers,
				userAnswers
			});
			assessment.correctAnswers = correctAnswers;
			assessment.passed = passed;
		}
		else {
			assessment.pages.forEach((page) => {
				const question = page.elements[0];
				const userAnswer = userAnswers[question.name];
				page.answer = userAnswer;
				if (page.elements.length > 1) {
					page.feedback = userAnswers[page.elements[1].name];
				}
			});
			assessment.correctAnswers = totalQuestions;
			assessment.passed = true;
		}
		if (onComplete) {
			onComplete(assessment);
		}
		setIsComplete(true);
	};

	survey.onComplete.add(handleComplete);

	const restartQuiz = () => {
		setIsComplete(false);
		setQuizResults(null);
		if (onRestart) {
			onRestart();
		}
		setSurvey(new Model(assessment));
	};

	const getAnswerText = (question, userAnswer) => {
		if (Array.isArray(userAnswer)) {
			return userAnswer
				.map((answerValue) => {
					const choice = question.choices?.find((choice) => choice.value === answerValue);
					return choice?.text || answerValue;
				})
				.join(", ");
		}
		else {
			const choice = question.choices?.find((choice) => choice.value === userAnswer);
			return choice?.text || userAnswer;
		}
	};

	return (
		<div className="surveyContainer gutters">
			{!isComplete ? (
				<Survey model={survey} />
			) : !assessment.Test ? (
				<div className="complete-page">
					<div className="complete-page-feedback">
						<h2>Thank you for completing the feedback survey</h2>
						<p>Your feedback has been submitted successfully. You may now navigate away from this page using the links below.</p>
					</div>
				</div>
			) : (
				<div className="complete-page">
					<div className="complete-page-result">
						<h2>{quizResults.passed ? "Congratulations, you passed!" : "Quiz Completed"}</h2>
						<p className={`complete-page-result-feedback ${quizResults.passed ? "quiz-passed" : "quiz-failed"}`}>
							Your score: <span className="quiz-score">{quizResults.scorePercentage.toFixed(2)}%</span>
							({quizResults.correctAnswers}/{quizResults.totalQuestions})
						</p>
								<p className="complete-page-result-text">
									{quizResults.passed ? (
										"Great job! You can now use the buttons below to navigate away from this page."
									) : (
										<>
											Unfortunately, you did not pass. You need at least <span>{assessment.PassPercentage}%</span> to pass this quiz. Try again, or use the buttons below to navigate away from this page.
										</>
									)}
								</p>
						{!quizResults.passed && (
							<Button flipped text={"Retake Quiz"} onClick={() => restartQuiz()} />
						)}
					</div>
							{quizResults.correctAnswers !== quizResults.totalQuestions && (
								<div className="complete-page-answers">
									<h2>Your Incorrect Answers:</h2>
									<div className="complete-page-answers-list">
										{assessment.pages.flatMap((page) =>
											page.elements
												.filter((question) => {
													const userAnswer = quizResults.userAnswers[question.name];
													const correctAnswer = question.correctAnswer;
													const isCorrect =
														Array.isArray(correctAnswer)
															? JSON.stringify(userAnswer?.sort()) === JSON.stringify(correctAnswer?.sort())
															: typeof userAnswer === "string" && typeof correctAnswer === "string"
																? userAnswer.trim().toLowerCase() === correctAnswer.trim().toLowerCase()
																: userAnswer === correctAnswer;
													return !isCorrect; 
												})
												.map((question) => {
													const userAnswer = quizResults.userAnswers[question.name];
													return (
														<div
															key={question.name}
															className="complete-page-answers-list-answer answer-wrong"
														>
															<span className="answer-icon">
																<FontAwesomeIcon icon={faCircleXmark} />
															</span>
															<h3>{question.title}</h3>
															<p>
																You answered: {getAnswerText(question, userAnswer)}{" "}
															</p>
														</div>
													);
												})
										)}
									</div>
									{!quizResults.passed && (
										<Button flipped text={"Retake Quiz"} onClick={() => restartQuiz()} />
									)}
								</div>
							)}
				</div>
			)}
		</div>
	);
};

export default SurveyContainer;
