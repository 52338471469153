import { faChevronRight, faCircleInfo, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MOBILE_WIDTH } from "../../utils/constants";
import "./Button.scss";

const ButtonType = {
	Primary: 1,
	Secondary: 2,
	Tertiary: 3,
	Delete: 4,
};

const Button = ({
	text = 'Button',
	type = ButtonType.Primary,
	to = null,
	flipped = false,
	state = null,
	onClick = null,
	disabled = false,
	disableUntilClickCompleted = false,
	newTab = false,
	icon = faChevronRight,
	tooltip = null,
	navigate = null,
	miniMobile = false,
}) => {
	const [handlingClickEvent, setHandlingClickEvent] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < MOBILE_WIDTH);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const typeMap = {
		[ButtonType.Primary]: "button-primary",
		[ButtonType.Secondary]: "button-secondary",
		[ButtonType.Tertiary]: "button-tertiary",
		[ButtonType.Delete]: "button-delete"
	};

	let buttonClass = typeMap[type];
	const flippedClass = flipped || buttonClass === "button-delete" ? "flipped" : "";
	const miniMobileClass = miniMobile && isMobile ? "mini-mobile" : "";

	// Prevent multiple clicks while previous click is processing.
	const handleClick = (e) => {
		if (handlingClickEvent) {
			return;
		}
		setHandlingClickEvent(true);
		if (disableUntilClickCompleted) {
			onClick && onClick(e, setHandlingClickEvent);
		} else {
			onClick && onClick(e);
			setHandlingClickEvent(false);
		}
		// Handle navigation if "to" prop is provided and navigate function exists
		if (to && navigate) {
			navigate(to, { state });
		}
	};

	const disabledClass = (disabled || handlingClickEvent) ? "disabled" : "";

	const Tooltip = tooltip ? <span className="button-tooltip"><FontAwesomeIcon icon={faCircleInfo} /> {tooltip}</span> : null;

	const ButtonContent = (
		<>
			{miniMobile ?
				(!isMobile && <span className="button-text">{text}</span>)
				:
				<span className="button-text">{text}</span>
			}
			<span className="button-arrow">
				<span className="button-arrow-icon">
					{buttonClass !== "button-delete" && <FontAwesomeIcon icon={faChevronRight} />}
					{buttonClass === "button-delete" && <FontAwesomeIcon icon={faTrashCan} />}
				</span>
			</span>
		</>
	);

	if (to && !navigate) {
		// Use Link component for regular buttons outside modals
		return (
			<div className="button-container">
				<Link
					to={to}
					state={state}
					className={`button ${buttonClass} ${flippedClass} ${disabledClass} ${miniMobileClass}`}
					target={newTab ? '_blank' : '_self'}
				>
					{ButtonContent}
				</Link>
				{Tooltip}
			</div>
		);
	}

	return (
		<div className="button-container">
			<div
				className={`button ${buttonClass} ${flippedClass} ${disabledClass} ${miniMobileClass}`}
				onClick={handleClick}
			>
				{ButtonContent}
			</div>
			{Tooltip}
		</div>
	);
};

export { Button, ButtonType };
